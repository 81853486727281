import * as React from "react"
import TitleImage from "../../../../assets/images/tiltitlogo.png";
const TiltitHeroSection = (props) => {
    
    return (
        <section className="page-header-section case-study-hero-section tiltit-hero-section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <p className="small section-description text-white">{props.preTitle}</p>
                        <h1 className="h1 large section-title text-white">{props.mainTitle}</h1>
                        {/* <div className="title-img-block">
                            <img 
                                src={TitleImage}
                                width="208px"
                                alt="Tiltit"
                            />
                        </div>   */}
                    </div>
                    {/* <div className="col-lg-12">
                        
                    </div> */}
                </div>
                <div className="image-block">
                    <img
                        src={props.image1x?.sourceUrl}
                        srcSet={props.image2x?.sourceUrl + " 2x, " + props.image1x?.sourceUrl + " 1x"}
                        width={props.image1x?.width}
                        alt={props.image1x?.altText}
                    />
                </div>
                <div className="mobile-image-block">
                    <img
                        src={props.mobileImage?.sourceUrl}
                        width={props.mobileImage?.width}
                        alt={props.mobileImage?.altText}
                    />
                </div>
            </div>
        </section> 
    )
}

export default TiltitHeroSection
